import Tracker from '@/util/tracker.js'

window.addEventListener('load', () => {
  Tracker.track('page loaded', {
    url: window.location.toString(),
    'detected country': window.upsellery?.detectedCountry,
    'detected language': window.upsellery?.detectedLanguage,
    'perceived country': window.upsellery?.perceivedCountry,
    'perceived language': window.upsellery?.perceivedLanguage,
    'url language': window.upsellery?.language,
    'url country': window.upsellery?.region,
    currency: '',
    clean_url: `${window.location.origin.toString()}${window.location.pathname.toString()}`,
  })
})

window.addEventListener('error', (ev) => {
  Tracker.track('page errored', {
    message: ev.message,
    filename: ev.filename,
    lineno: ev.lineno,
    colno: ev.colno,
    url: window.location.toString(),
  })
})

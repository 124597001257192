import { put } from '@/util/requests.js'

import { apiVisitors } from '@/api'

/** @returns {Promise<Termly>} */
export async function getTermly() {
  if (window.Termly != null) {
    return window.Termly
  }

  return await new Promise((resolve) => {
    document.addEventListener(
      'termly:initialized',
      () => resolve(window.Termly),
      { once: true },
    )
  })
}

document.addEventListener(
  'termly:consent',
  async (/** @type {CustomEvent} */ ev) => {
    const { uuid } = ev.detail.event

    const updateURL = apiVisitors.update.path()
    await put(updateURL, {
      termly_uuid: uuid,
    })
    console.log('Termly UUID updated.')
  },
)
